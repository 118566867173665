@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  display: flex;
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionImages {
  position: relative;

  @media (--viewportMedium) {
    padding: 10px;
    border-radius: var(--borderRadiusMedium);
    background-color: var(--matterColorLight);
  }
}

.images {
  & > div:not(:first-of-type) {
    display: none;
  }

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-flow: row;
    gap: 10px;

    & > div {
      cursor: pointer;

      &:not(:first-of-type) {
        display: block;
      }

      &:first-of-type {
        grid-area: 1 / 1 / 3 / 3;
      }
    }
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: transparent; /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.ownListingText {
  composes: h4 from global;

  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.editListingLink {
  composes: h4 from global;

  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

/* Used for calendar weekly and daily
views, because it overflows */
.bookingsCalendarWrapper {
  display: flex;
}

.bookingsCalendar {
  background-color: var(--matterColorLight);
  margin: 24px 0 36px;
  padding: 24px;

  @media (--viewportMedium) {
    padding: 24px 36px;

    & > div:not(:last-of-type) {
      min-height: 630px;
      max-height: 630px;
    }
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  transition: var(--transitionStyleButton);
  object-fit: cover;

  @media (--viewportMedium) {
    /* position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh; */
    border-radius: var(--borderRadiusMedium);

    &:hover {
      transform: scale(1.005);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  color: var(--matterColor);
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);
  border: 1px solid var(--colorGrey100);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  width: 100%;
  min-width: 0;

  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.bodyContent {
  @media (--viewportMedium) {
    display: flex;
    gap: 24px;
    position: relative;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 36px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 36px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 36px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
    position: sticky;
    top: calc(var(--topbarHeightDesktop) + 24px);
    align-self: flex-start;
  }
}

.sectionAvatar {
  display: flex;
  gap: 14px;

  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.avatarMobile {
  display: flex;
  width: 60px;
  height: 60px;

  background-color: var(--matterColorLight);

  & > svg {
    width: 60px;
    height: 60px;

    & path {
      fill: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 68px;
  height: 68px;

  background-color: var(--matterColorLight);

  & > svg {
    width: 68px;
    height: 68px;

    & path {
      fill: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    display: flex;
  }
}

.userName {
  display: block;
  font-weight: var(--fontWeightBold);
  line-height: 18px;
  font-size: 16px;
}

.sectionHeading {
  padding: 0 24px;
  margin-top: 36px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 22px;
    margin-bottom: 10px;
  }
}

.headerWrapper {
  display: flex;
  flex-direction: column-reverse;

  @media (--viewportMedium) {
    flex-direction: column;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;

  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--matterColorDark);
  font-size: 18px;

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 8px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.headingInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.headingButtons {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    gap: 20px;
    white-space: nowrap;
  }
}

.headingButtonsMobile {
  position: absolute;
  top: 0;
  right: 0;

  margin: 10px;

  display: flex;
  gap: 20px;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: none;
  }
}

.author {
  width: 100%;
  composes: h5 from global;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  composes: a from global;
  composes: h5 from global;
  margin: 0;
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.featuresTitle {
  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-bottom: 12px;
  }
}

.featuresContent {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.featuresLabel {
  text-align: center;
  color: var(--matterColorEE);
  min-width: 110px;
  border-radius: var(--borderRadius20);

  padding: 8px 16px;
  background-color: var(--matterColorNegative);
  text-decoration: line-through;

  &.featuresLabelSelected {
    box-shadow: var(--boxShadowButton);
    color: var(--matterColorLight);
    background-color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.sectionDetails {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.detailsTitle {
  /* Font */
  composes: h3 from global;
  font-size: 16px;

  margin-top: 0;
  margin-bottom: 12px;
  @media (--viewportMedium) {
    margin-top: 0;
    font-size: 18px;
  }
}
.detailsContent {
  display: grid;

  grid-row-gap: 22px;
  grid-template-columns: repeat(2, 1fr);

  & li {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: var(--fontWeightBold);
  }
}

.sectionDetailsIcon {
  min-width: 35px;
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.locationTitle {
  /* Font */
  composes: h3 from global;
  font-size: 16px;

  margin-top: 0;
  margin-bottom: 12px;
  @media (--viewportMedium) {
    font-size: 18px;
    margin-top: 0;
  }
}

.reviewsHeading {
  composes: h3 from global;

  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  composes: h3 from global;

  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.locations {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.iconTextWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  max-width: 46%;

  & > svg {
    min-width: 35px;
    background: var(--matterColorLight);
    border-radius: 50%;
    padding: 6px;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vw - 48px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  border-radius: var(--borderRadius20);
  cursor: pointer;

  & img {
    border-radius: var(--borderRadius20);
  }

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.additionalInfo {
  display: none;
  @media (--viewportLarge) {
    display: block;
    padding: 30px;
    margin-top: 36px;
    border-radius: var(--borderRadius20);
    box-shadow: var(--boxShadowButton);
  }
}

.additionalInfoMobile {
  display: block;
  padding: 30px;
  margin: 0 24px 36px 24px;
  border-radius: var(--borderRadius20);
  box-shadow: var(--boxShadowButton);

  @media (--viewportMedium) {
    display: block;
    padding: 50px 70px 30px 70px;
    margin: 0;
    border-radius: 0;
    box-shadow: none;

    background-color: var(--matterColorEE);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.additionalInfoContent {
  @media (--viewportMedium) {
    display: flex;
    gap: 20px;

    & div {
      flex: 1;
    }
  }

  @media (--viewportLarge) {
    flex-direction: column;
    gap: 20px;
  }
}

.additionalInfoTitle {
  display: flex;
  align-items: center;
  gap: 14px;
  font-size: 16px;
  font-weight: var(--fontWeightBold);

  &:first-child {
    margin-top: 0;
  }

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.additionlInfoParagraph {
  color: var(--matterColor70);
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  letter-spacing: 0px;

  margin-bottom: 26px;
}

.cancellationPolicy {
  & > span:not(:last-of-type) {
    display: block;
    margin-bottom: 16px;
  }
}

.additionlInfoLink {
  color: var(--matterColorDark);
  font-weight: var(--fontWeightBold);
}

.additionlInfoBtnWrapper {
  display: flex;
  justify-content: center;

  margin-top: 40px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
}

.secondaryButton,
.additionlInfoButton {
  composes: buttonSecondary from global;

  color: var(--matterColorDark);
  width: auto;
  display: inline-block;
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.reviewsGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;

  margin-bottom: 20px;

  & li {
    border-radius: var(--borderRadius20);
    padding: 20px;
    background-color: var(--matterColorLight);
    margin-bottom: auto;

    &:last-of-type {
      margin-bottom: auto;
    }
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.modalContainer {
  composes: marketplaceModalBaseStyles from global;
  min-height: 100vh;
  height: 100%;

  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    flex-basis: 830px;
    min-height: auto;
    height: auto;
    padding: 34px 36px;
    margin-left: 24px;
    margin-right: 24px;
  }
}

.modalContent {
  margin-top: 16px;
}

.modalReviews {
  margin: 24px 0;
}

.listingsInRegion {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.carousel {
  justify-content: space-between;

  @media (--viewportLarge) {
    display: flex;
  }
}

.carouselContent {
  @media (--viewportLarge) {
    padding: 4px;
    padding-bottom: 8px;
    overflow-x: auto;

    flex-basis: 1056px;
    flex-grow: 0;

    & > * {
      flex-shrink: 0;
      margin: 0;
    }
  }
}

.listingCard {
  margin-right: 20px;
  height: 100%;

  @media (--viewportLarge) {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.noReviews {
  font-size: 16px;
  font-style: italic;
  color: var(--matterColor);
}

.marketplaceColorText {
  color: var(--marketplaceColor);
}
