@import '../../../styles/customMediaQueries.css';

.root {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.tabs {
  box-shadow: none;
  border-radius: unset;
  gap: 12px;
}

.tab {
  & > button {
    width: 60px;
    background-color: unset;
    color: var(--matterColorDark);
    padding: 6px 12px;
    line-height: 24px;

    &:focus {
      background-color: var(--marketplaceColorLight);
    }

    &:hover {
      box-shadow: unset;
      background-color: var(--marketplaceColorLight);
      color: var(--matterColorDark);
    }
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--matterColor);
  font-size: 14px;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.mainDescription {
  margin-bottom: 20px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 12px;
}

.descriptionTitle {
  /* Font */
  composes: h3 from global;

  font-size: 16px;

  margin: 0 12px 0 0;
  display: flex;
  align-items: center;
  gap: 4px;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.descriptionDayTitle {
  /* Font */
  composes: h5 from global;

  color: var(--matterColor);

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
